import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      product: state => state['sayl-front.catalog-product'].product,
    }),

    figures(){
      return this.$basil.get(this.product, 'figures', [])
    },

    hasFigure(){
      return this.figures.length > 0
    },
  }
}
