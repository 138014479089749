<template>
  <img v-bind="attrs">
</template>

<script>
import Config from '@/config'

export default {
  name: 'UiImage',

  props: {
    alt: {
      type: String
    },
    
    fit: {
      type: String,
      default: 'contain'
    },

    format: {
      type: String,
      default: 'jpeg'
    },
    
    quality: {
      type: Number,
      default: 70
    },

    height: {
      type: Number
    },

    src: {
      type: String,
      required: true
    },

    width: {
      type: Number
    },
  },

  data(){
    return {
      active: false
    }
  },

  computed: {
    attrs(){
      let ret = {
        class: this.classes,
        ref: 'img',
        [!!this.active ? 'src' : 'data-src']: this.url
      }

      if (this.alt){
        ret.alt = this.alt;
      }

      return ret;
    },

    classes(){
      return {
        'ui-image': true,
        '-is-active':this.active
      }
    },

    url(){
      const bucket = 'cdn-infinitycommerce';
      const cdn = 'https://dest874vfc95i.cloudfront.net/';
      const u = new URL(this.src);
      const key = u.pathname.substr(1);

      let payload = {
        bucket,
        key,
        edits: {
          resize: {
            background: {
              r: 255,
              g: 255, 
              b: 255,
              alpha: 0
            }
          }
        }
      }

      if (this.width || this.height){
        if (!!this.width) payload.edits.resize.width = this.width;
        if (!!this.height) payload.edits.resize.height = this.height;
        payload.edits.resize.fit = this.fit;
      }

      payload = btoa(JSON.stringify(payload));
      return [cdn, payload].join('');
    }
  },

  methods: {
    show(){
      this.active = true;
    }
  },

  mounted(){
    if (!!!window.IntersectionObserver){
      this.show();
    }
    else{
      let img = this.$refs.img;
      this.$bus.$emit('ui.observe.start', img);
      this.$bus.$on('ui.observe.visible', (target) => {

        if (target.target === img && target.isIntersecting){
          this.$bus.$emit('ui.observe.stop', img);
          this.show();
        }
      })
    }
  }
}
</script>
