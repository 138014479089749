<template>
  <div class="option__fields">
    <div
      v-for="value in values"
      :key="value.code"
      :class="fieldClasses(value)">

      <!-- Multiple values -->
      <forms-radio
        v-if="!isOne"
        :value="value.code"
        :name="id"
        v-model="choice"
        @change="propagate"
      >{{ value.name }}</forms-radio>

      <!-- Only One value -->
      <forms-checkbox
        v-if="isOne"
        :name="id"
        :value="value.code"
        :checked="value.code === choice"
        @change="onCheckboxChange"
        >{{ value.name }}</forms-checkbox>

      <span
        class="option__price"
        v-if="!value.isHidden && hasPriceVariation(value)">{{ priceVariationLabel(value.variationPrice) }}</span>

      <data-tag
        v-if="!isHidden && value.isHidden"
        size="s"
      >{{$t('resto.option_unavailable')}}</data-tag>
    </div>
  </div>
</template>

<script>
import MixinCurrency from '@/helpers/currency'
import MixinOption from '@/modules/catalog/mixins/option'

export default {
  name: 'ProductOptionMultipleChoice',

  mixins: [
    MixinCurrency,
    MixinOption
  ],

  computed: {
    isOne(){
      return this.values.length === 1
    },

    price() {
      let ret = 0;
      if (this.values && this.values.length){

        this.values.forEach(v => {
          if (v.code === this.choice){
            let price = 0;

            price += !this.$basil.isEmpty(this.basePrice) ? this.basePrice : 0;
            price += !this.$basil.isEmpty(v.variationPrice) ? v.variationPrice : 0;

            ret = price;
          }
        })


        for (let i = 0; i < this.values.length; i++) {
          if (this.values[i].code === this.choice) {
            let price = 0;
            if (!this.$basil.isNil(this.basePrice)) {
              price += this.basePrice;
            }
            if (!this.$basil.isNil(this.values[i].price_variation)) {
              price += this.values[i].price_variation;
            }
            ret = price;
          }
        }
      }

      return ret;
    },

    valid() {
      return this.required ? this.choice !== null : true;
    },
  },

  methods: {
    onCheckboxChange(checked, value){
      this.choice = checked === true ? value.value : null;
      this.propagate();
    }
  },

  created() {
    if(this.values) {
      let val = this.values.find(v => v.isDefault && !v.isHidden) || null;
      this.choice = val != null ? val.code : !this.$basil.isNil(this.selection) ? this.selection : null;
    }
    this.propagate();
  },
};
</script>
