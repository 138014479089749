<template>
  <div :class="classes">
    <div class="product-price__row">
      <!-- Qty -->
      <div
        class="product-price__qty"
        v-if="qty > 0"
      >{{ qty }}x</div>

      <!-- Prefix -->
      <div
        class="product-price__prefix"
        v-if="prefix && visible"
      >{{ prefix }}</div>

      <!-- Price -->
      <div
        class="product-price__price"
        v-if="(!prefix && visible) || (!inPromo && visible)"
      >{{ price }}</div>

      <!-- Promo Price -->
      <div
        class="product-price__promo"
        v-if="inPromo && visible"
      >{{ promo }}</div>

      <!-- Promo Tag -->
      <data-tag
        class="product-price__promotag"
        :size="$pepper.Size.S"
        v-if="inPromo && inStock"
      >{{ $t('resto.promotion') }}</data-tag>
    </div>
  </div>
</template>

<script>
import MixinCurrency from '@/helpers/currency'

export default {
  name: 'ProductPrice',

  mixins: [
    MixinCurrency
  ],

  props: {
    autoHide: {
      type: Boolean,
      default: false
    },

    qty: {
      type: Number,
      default: 0
    },

    product: {
      type: Object
    },

    signed: {
      type: Boolean,
      default: false
    },

    size: {
      type: String,
      default: 'm'
    },

    variant: {
      type: Object
    },
  },

  computed: {
    classes() {
      return {
        'product-price': true,
        '-promo': this.inPromo,
        '-depleted': !this.inStock,

        '-l': this.size === 'l'
      }
    },

    inPromo() {
      return this.item.inPromo
    },

    inStock() {
      return this.item.inStock
    },

    isHidden() {
      return this.item.hidden
    },

    visible() {
      return this.autoHide === false || (this.autoHide === true && (this.priceValue !== 0 && !isNaN(this.priceValue)))
    },

    item() {
      return this.variant ? this.variant : this.product
    },

    prefix() {
      return this.item.price.min ? this.$t('resto.starting_at') : null
    },

    priceValue() {
      let p = this.item.price
      let v
      if (p.hasOwnProperty('value')){
        v = p.min ? p.min : p.value
      }
      else {
        v = p
      }

      return v
    },

    price() {
      return this.toCurrency(this.priceValue, this.signed)
    },

    promoValue() {
      let p = this.item.promoPrice
      let v
      if (p.hasOwnProperty('value')){
        v = p.min ? p.min : p.value
      }
      else{
        v = p
      }

      return this.inPromo ? v : null
    },

    promo() {
      return this.toCurrency(this.promoValue, this.signed)
    },
  },
}
</script>
