<template>
  <div
    :class="classes"
    :key="key"
    @click="onChange">
    <div
      v-for="variant in variants"
      :key="`${variant.id}_${product.id}`"
      :class="getVariantClass(variant)">

      <forms-radio
        :disabled="!variant.available"
        :id="$basil.uniqId(variant.id)"
        :name="`variant_${product.id}`"
        :value="variant"
        v-model="choice"
        @change="onChange"
      />

      <label
        :for="variant.id"
        class="view-catalog-product__variant-body">
        <div class="view-catalog-product__variant-info">
          <div class="view-catalog-product__variant-label">{{ variant.name }}</div>

          <div
            v-if="variant.description"
            class="view-catalog-product__variant-description"
          >{{ variant.description }}</div>

          <data-tag
            class="view-catalog-product__variant-availability"
            :size="$pepper.Size.S"
            v-if="!variant.available"
          >{{ $t('resto.option_unavailable') }}</data-tag>
        </div>

        <product-price
          autoHide
          :variant="variant"
        />
      </label>

    </div>
  </div>
</template>

<script>
import MixinCurrency from '@/helpers/currency'
import ProductPrice from '@/modules/catalog/components/product/price'

export default {
  name: 'CatalogProductVariants',

  mixins: [ MixinCurrency ],

  components: {
    ProductPrice
  },

  model: {
    prop: 'item',
    event: 'change'
  },

  props: {
    product: {
      type: Object,
      required: true
    },
    item: {
      type: Object
    }
  },

  data() {
    return {
      choice: null,
      key: 0
    }
  },

  computed: {
    classes(){
      return {
        'view-catalog-product__variants': true,
      }
    },

    variants() {
      let ret = this.product.variants
      return ret.filter(v => !v.hidden)
    },
  },

  watch: {
    product(){
      this.reset()
    }
  },

  methods: {
    reset() {
      this.choice = this.product.variants.find((v) => v.available === true)
      if(this.item && this.item.variant) {
        this.choice = this.product.variants.find(v => v.id === this.item.variant.id)
      }
      this.key++
    },

    onChange() {
      this.$emit('change', this.choice, this.product)
    },

    getVariantClass(v) {
      return {
        'view-catalog-product__variant': true,
        '-is-disabled': !this.$basil.get(v, 'available', false)
      }
    },

    getVariantLabel(variant){
      return variant.inStock ? this.getVariantPrice(variant) : this.$t('resto.no_stock_variant')
    },

    getVariantPrice(variant) {
      return this.toCurrency(variant.unitPrice)
    },

    getVariantPromoPrice(variant){
      return this.toCurrency(variant.promoPrice)
    },

    hasPromoPrice(variant){
      return !!variant.promoPrice
    }
  },

  mounted() {
    this.reset()
  }
};
</script>
