<template>
  <div
    v-if="values"
    class="option__fields">

    <!-- Loading -->
    <template v-if="loading">
      <ui-loader/>
    </template>

    <!-- Form -->
    <div
      v-else
      v-for="value in values"
      :key="value.code"
      :class="fieldClasses(value)">

      <forms-checkbox
        :label="value.title"
        :value="value.code"
        :disabled="isValueDisabled(value)"
        v-model="choice[value.code]"
        @change="onInput"
      >{{ value.name }}</forms-checkbox>

      <span
        class="option__price"
        v-if="!value.isHidden && hasPriceVariation(value)">{{ priceVariationLabel(value.variationPrice) }}</span>

      <data-tag
        v-if="!isHidden && value.isHidden"
        size="s"
      >{{$t('resto.option_unavailable')}}</data-tag>
    </div>
  </div>
</template>

<script>
import MixinOption from '@/modules/catalog/mixins/option'
import MixinCurrency from '@/helpers/currency'

export default {
  name: 'ProductOptionMultipleChoiceMultiple',

  mixins: [
    MixinCurrency,
    MixinOption
  ],

  data(){
    return {
      quantity: 0
    }
  },

  computed: {
    loading() {
      return !this.choice || Object.keys(this.choice).length == 0;
    },

    maxReached() {
      return this.$basil.isNil(this.max) ? false : this.quantity >= this.max;
    },

    price() {
      let price = 0;
      if (this.quantity >= 1) {
        if (!this.$basil.isEmpty(this.basePrice)) {
          price += this.basePrice;
        }
      }

      for (const o in this.choice) {
        if (this.choice[o]) {
          for (let i = 0; i < this.values.length; i++) {
            if (this.values[i].code === o) {
              if (!this.$basil.isEmpty(this.values[i].variationPrice)) {
                price += this.values[i].variationPrice;
              }
            }
          }
        }
      }
      return price;
    },

    valid() {
      let ret = true;

      if (!this.$basil.isNil(this.min)) {
        ret = this.quantity >= this.min;
      }

      else if (this.required) {
        ret = this.quantity >= 1;
      }

      return ret;
    },
  },

  methods: {
    isValueDisabled(value) {
      return this.quantity > 0 && this.choice ? !this.choice[value.code] && this.maxReached : false
    },

    onInput(){
      this.updateQuantity();
      this.propagate();
    },

    updateQuantity(){
      this.quantity = 0;

      Object.keys(this.choice).forEach(k => {
        this.choice[k] === true ? this.quantity++ : null
      });
    }
  },

  mounted() {
    if (!this.selection){
      this.choice = {};
      this.values.forEach( e => {
        this.choice[e.code] = !e.isDefault || e.isHidden ? false : true;
      });

      this.propagate();
    }
  },

};
</script>
