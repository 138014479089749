<template>
  <button
    :class="classes"
    @click="onItemClick"
    :disabled="isDisabled">
    <figure
      class="card-product__figure"
      :class="{ '-no-figure': !hasFigures }">
      <ui-image
        :alt="product.name"
        class="card-product__illustration"
        :class="{ '-no-figure': !hasFigures }"
        fit="cover"
        :height="320"
        :src="productPicture"
        :width="320"
        v-if="productPicture != null"
      />

      <!-- Tags -->
      <div
        class="card-product__tags"
        v-if="!isAvailable || $basil.get(product, 'adultOnly', false) || !inStock">
        <data-tag
          class="card-product__availability"
          :appearance="$pepper.Appearance.PRIMARY"
          :size="$pepper.Size.S"
          v-if="$basil.get(product, 'adultOnly', false)"
        >{{  $t('resto.product_is_age_restricted') }}</data-tag>

        <data-tag
          class="card-product__availability"
          :size="$pepper.Size.S"
          v-if="!isAvailable"
        >{{ $t('resto.option_unavailable') }}</data-tag>

        <data-tag
          class="card-product__depletedtag"
          :size="$pepper.Size.S"
          v-if="!inStock"
        >{{ $t('resto.no_stock_variant') }}</data-tag>
      </div>

      <!-- <button
        :class="{ '-no-figure': product.figures.length === 0 }"
        class="card-product__hover"
        @click.stop="onFigureClick"
        :disabled="!isAvailable">
        <ui-icon :glyph="icon"/>
      </button> -->
    </figure>

    <div class="card-product__body">
      <div class="card-product__first">
        <div class="card-product__row">
          <div class="card-product__title">{{ product.name }}</div>
        </div>

        <div class="card-product__row">
          <p
            class="card-product__description"
            ref="description"
          >{{ product.description }}</p>
        </div>

        <!-- Allergens -->
        <div
          class="card-product__allergens"
          v-if="hasAllergens">
          <span class="card-product__allergens-title">{{ $t('resto.product_contains_allergens')}}</span>

          <article
            class="card-product__allergen"
            :key="i"
            v-for="(a, i) in pAllergens"
          >{{ a }}</article>
        </div>

        <!-- Preparation time -->
        <div
          class="card-product__preparation"
          v-if="hasPreparationTime">
          <span class="card-product__preparation-title">{{ $t('resto.product_has_preparation_time',  { value: preparationTime }) }}</span>
        </div>

        <!-- Preparation time -->
        <div
          class="card-product__preparation"
          v-if="validityPeriod">
          {{ $t('resto.product_validity_period', { date: validityPeriodDate, days: validityPeriod }) }}
        </div>
      </div>

      <div class="card-product__second">
        <product-price
          :product="product"
          :qty="productCount"
          v-if="!hidePrice"
        />
      </div>

      <!-- <footer class="card-product__footer"> -->
        <button
          class="card-product__action"
          @click.stop="onFigureClick"
          :disabled="!isAvailable">
          <ui-icon :glyph="icon"/>
        </button>
      <!-- </footer> -->
    </div>
  </button>
</template>

<script>
import { mapState } from 'vuex'

import { CartItem } from '@sayl/front-core'

import ProductPrice from '@/modules/catalog/components/product/price'
import UiImage from '@/components/ui/image'

import MixinAllergens from '../../mixins/allergens'
import MixinError from '../../mixins/error'
import MixinMultishop from '@/helpers/multishop'

import moment from 'dayjs'

export default {
  name: 'CatalogProductDefaultCard',

  components: {
    ProductPrice,
    UiImage
  },

  inject: [
    '$cart',
    '$embed',
    '$user'
  ],

  mixins: [
    MixinAllergens,
    MixinError,
    MixinMultishop
  ],

  props: {
    appearance: {
      type: String,
      default: 'default'
    },

    disabled: {
      type: Boolean,
      default: false
    },

    hidePrice: {
      type: Boolean,
      default: false
    },

    product: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      debounce: null,
      enabled: true,
      item: null
    }
  },

  computed: {
    ...mapState({
      cart: state => state['sayl-front.cart'].cart,
    }),

    classes() {
      return {
        'card-product': true,
        '-active': this.productCount > 0,
        '-depleted': !this.isAvailable || !this.inStock,
        '-disabled': this.isDisabled === true,
        '-hidden': this.isHidden === true,
        '-no-price': this.hidePrice === true,
        '-promo': this.$basil.get(this.product, 'inPromo', false),
        '-is-inverted': this.$basil.get(this.oat, 'is_layout_product_inverted', false) === true,

        '-is-subtle': this.appearance === 'subtle'
      }
    },

    isDisabled() {
      return this.disabled || !this.inStock
    },

    inStock() {
      return this.$basil.get(this.value, 'inStock', true)
    },

    value() {
      return this.hasVariants ? this.$basil.get(this.product, 'variants.0', {}) : this.product
    },

    hasAllergens() {
      return this.pAllergens && this.$basil.get(this.pAllergens, 'length', 0) > 0
    },

    hasFigures() {
      return this.product && this.$basil.get(this.product, 'figures.length', 0) > 0
    },

    hasOptions() {
      return this.product && this.$basil.get(this.product, 'options.length', 0) > 0
    },

    hasPreparationTime() {
      return this.preparationTime > 0
    },

    hasVariants() {
      return this.product && this.$basil.get(this.product, 'variants.length', 0) > 1
    },

    isAvailable() {
      return this.$basil.get(this.product, 'available', true)
    },

    isBundle() {
      return this.product && this.product.type === 'bundle'
    },

    isCatalogReadonly() {
      return this.$basil.get(this.oat, 'is_catalog_readonly', false)
    },

    isGiftVoucher() {
      return this.product && this.product.type === 'gift_voucher'
    },

    isHidden() {
      return this.$basil.get(this.product, 'hidden', false)
    },

    icon() {
      let ret = (this.hasOptions || this.isBundle || this.hasVariants || this.isGiftVoucher || this.isCatalogReadonly || !this.isAvailable) ? 'search' : 'plus'
      return ret
    },

    lang() {
      let ret = this.$basil.get(this.user, 'lang', 'all')
      return ret
    },

    pAllergens() {
      let pAllergens = this.$basil.get(this.product, 'allergens', [])
      let ret = this.allergens.filter(a => pAllergens.includes(a.id))
      return ret.map(a => this.getAllergenName(a))
    },

    preparationTime() {
      return this.product && this.$basil.get(this.product, 'preparationTime', 0)
    },

    productCount() {
      let ret = null

      if(this.cart) {
        ret = this.cart.items.filter(p => p.product.id === this.product.id).reduce((acc, item) => {
          acc += item.quantity
          return acc
        }, 0)
      }
      return ret
    },

    productPicture() {
      let ret = [
        this.$basil.get(this.product, 'figures[0]', null),
        this.$basil.get(this.product, 'category.picture', null),
        this.$basil.get(this.subshop, 'logo', null),
      ].filter(s => !this.$basil.isNil(s))

      ret = ret.length > 0 ? ret[0] : null
      return ret
    },

    to() {
      return { name: 'sayl-front-catalog.product', params: {
          embed: this.$route.params.embed,
          product: this.product.id,
          service: this.$route.params.service
        }
      }
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },

    validityPeriod() {
      return this.isGiftVoucher && this.$basil.get(this.product, 'validityPeriod', null) != null ? 
        this.$basil.get(this.product, 'validityPeriod', null) : null
    },

    validityPeriodDate() {
      let ret = this.validityPeriod

      if(ret) {
        ret = moment().add(ret, 'days')
      }

      return ret ? this.$basil.i18n.date(ret.toDate()) : null
    }
  },

  methods: {
    add() {
      if(this.$basil.isNil(this.item)) {
        this.item = CartItem.create({
          product: this.product,
          options: [],
          quantity: 1,
          isNew: true,
          shopId: this.$basil.get(this.subshop, 'id', null)
        });
      } else {
        this.item.quantity++
      }

      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.enabled = false

        if(!this.$basil.isNil(this.item)) {
          this.$cart.add({ item: this.item, refresh: this.isMultiShop === true })
            .then(() => {
              this.enabled = true
              this.item = null
            })
            .catch((error) => this.handleError(error))
        } else {
          this.handleError({ status: 422 }, false)
        }
      }, 250)
    },

    formatProductPrice(price) {
      let ret = this.toCurrency(price.value)

      if (price.min && price.max) {
        ret = this.toRangeCurrency({min: price.min, max: price.max})
      }

      return ret;
    },

    onFigureClick() {
      let ret = (this.hasOptions || this.isBundle || this.hasVariants || this.isGiftVoucher || this.isCatalogReadonly) ? this.view : this.add

      ret()
    },

    onItemClick() {
      this.view()
    },

    view() {
      this.$router.push( this.to ).catch(() => {})
    },
  },

  mounted() {
    try {
      $clamp(this.$refs.description, { clamp: 2 })
    }
    catch (error) {}
  }
}
</script>
