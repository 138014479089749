export default {
  methods: {
    handleError(error) {
      $console.error(error)

      let message = error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.message ?
                    error.response.data.message : null;

      if(message != null && message.includes('stock')){
        message = this.$t('resto.error_adding_product_message_stock');
      } else {
        message = this.$t('resto.error_adding_product_message');
      }

      this.$notification({
        title: this.$t('resto.error_adding_product_title'),
        message,
        type: 'error'
      })
    }
  }
}
