<template>
  <div
    v-if="!loading && products.length > 0"
    class="product-related">

    <h6 class="product-related__title">{{ $t('resto.related_products')}}</h6>

    <div class="product-related__items">
      <article
        :class="klasses(product)"
        v-for="product in products"
        :key="product.id">

        <!-- Checkbox -->
        <forms-checkbox
          class="product-related__field"
          :key="key + product.id"
          :checked="isChecked(product)"
          @change="(value) => onChangeSelection(value, product)"
        />

        <!-- Figure -->
        <figure
          v-if="product.figures.length > 0"
          class="product-related__figure">
          <ui-image
            v-if="product.figures.length"
            class="card-product__illustration"
            :width="200"
            :height="200"
            :src="product.figures[0]"
            :alt="product.name"
          />
        </figure>

        <!-- Preview -->
        <div class="product-related__preview">
          <div class="product-related__label">
            <!-- QTY -->
            <forms-qty
              v-if="selection[product.id] != null"
              :min="1"
              @change="(value) => onChangeQty(value, product)"
              v-model="quantities[product.id]"
              size="s"
            />

            <!-- Product -->
            <div class="product-related__first">{{ product.name }}</div>

            <!-- Link to product -->
            <router-link
              :to="productRoute(product)"
              class="product-related__link">
              <span>{{ $t('resto.view_related_product') }}</span>

              <ui-icon
                class="product-related__icon"
                glyph="arrow-right"
              />
            </router-link>
          </div>

          <!-- Price -->
          <product-price
            class="product-related__price"
            :product="product"
          />
        </div>

        <!-- Variants -->
        <product-variant
          class="product-related__variants"
          :key="$basil.uniqId()"
          @change="onChangeVariant"
          :item="selection[product.id]"
          :product="product"
          v-if="hasMultipleVariants(product)"
        />

        <!-- Options -->
        <div
          v-if="hasOptions(product)"
          class="product-related__options view-catalog-product__options">
          <product-option
            v-for="option in selection[product.id].options"
            :key="option.id"
            :option="option"
            @selection-change="(values) => onChangeOptions(values, option, product)"
          />
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import FormsQty from '@/components/forms/qty'
import UiImage from '@/components/ui/image'

import ProductOption from './option'
import ProductPrice from './price'
import ProductVariant from './variant'

import MixinMultishop from '@/helpers/multishop'

export default {
  name: 'RelatedProducts',

  components: {
    FormsQty,
    ProductOption,
    ProductPrice,
    ProductVariant,
    UiImage,
  },

  inject: [
    '$cart',
    '$catalog',
    '$embed',
  ],

  mixins: [
    MixinMultishop
  ],

  props: {
    errors: {
      type: Array
    }
  },

  data() {
    return {
      loading: true,
      key: 0,
      selection: {},
      quantities: {}
    }
  },

  computed: {
    products() {
      let ret = [];

      !this.loading && this.$catalog.related.products.map(p => {
        ret.push(p)
      })

      return ret
    },
  },

  watch: {
    errors() {
      this.key++
    }
  },

  methods: {
    hasMultipleVariants(product) {
      return this.$basil.get(this.selection, `${product.id}.product.variants`, []).length > 1
    },

    hasOptions(product) {
      return this.$basil.get(this.selection, `${product.id}.options`, []).length > 0
    },

    isChecked(product) {
      return this.selection[product.id] != null
    },

    klasses(product) {
      return {
        'product-related__item': true,
        '-selected': this.selection[product.id] != null,
        '-no-figure': product.figures.length === 0
      }
    },

    onChangeOptions(value, option, item) {
      this.errors.splice(this.errors.indexOf(item.id));

      if (!option){
        return;
      }

      let opt = this.selection[item.id].options.find(o => o.id === option.id);

      if (!opt){
        return;
      }

      opt.selection = value.selection instanceof Object ?
                      Object.assign({}, value.selection):
                      value.selection;

      this.$emit('selection', this.selection)
    },

    onChangeQty(value, product) {
      if(this.selection[product.id] != null) {
        this.selection[product.id].quantity = value
      }

      this.$emit('selection', this.selection)
    },

    onChangeSelection(value, item) {
      this.errors.splice(this.errors.indexOf(item.id));

      if(this.$basil.isNil(this.selection[item.id])) {
        this.$catalog.product.view({ id: item.id })
          .then(({ product }) => this.$cart.createItem({ product, options: product.options, quantity: this.quantities[product.id], shopId: this.$basil.get(this.subshop, 'id', null) }))
          .then((cartItem) => {
            this.selection[item.id] = cartItem;
            this.key++;
            this.$emit('selection', this.selection)
          })
          .catch((e) => $console.error(e))
      } else {
        delete this.selection[item.id];
        this.key++;

        this.$emit('selection', this.selection)
      }
    },

    onChangeVariant(value, item) {
      this.errors.splice(this.errors.indexOf(item.id));
      this.selection[item.id].product.variant = value;
      this.selection[item.id].variant = value;
      this.key++;
    },

    productRoute(product) {
      return {
        name: 'sayl-front-catalog.product',
        params: {
          service: this.$route.params.service,
          product: product.id,
        }
      }
    },
  },

  mounted() {
    this.loading = true;

    this.$catalog.related.get(this.$route.params.product)
      .then((products) => products.forEach(p => this.quantities[p.id] = 1))
      .catch(e => console.error(e)) // Todo: Add bugsnag
      .finally(() => this.loading = false)
  }
}
</script>
