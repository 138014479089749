<template>
  <div :class="classes">
    <!-- Header -->
    <h2 class="option__title group">
      <span>{{ option.name }}</span>

      <data-tag
        v-if="option.isHidden"
        size="s"
      >{{$t('resto.option_unavailable')}}</data-tag>
    </h2>

    <!-- Description -->
    <p
      v-if="option.description"
      class="option__description"
    >{{ option.description }}</p>

    <div class="option__hint">
      <!-- Required -->
      <span
        v-if="option.required"
        class="option__hintitem"
      ><ui-icon glyph="asterisk" />{{ $t('resto.required') }}</span>

      <!-- Min value -->
      <span
        class="option__hintitem"
        v-if="hasMin"
      >{{ $t('resto.option_min_value_needed_label', { min: option.min }) }}</span>

      <!-- Max value -->
      <span
        class="option__hintitem"
        v-if="hasMax"
      >{{ $t('resto.option_max_value_needed_label', { max: option.max }) }}</span>

      <!-- BasePrice -->
      <span
        class="option__hintitem"
        v-if="option.price.value !== 0"
      >{{ basePriceLabel }}</span>
    </div>

    <!-- Forms Fields -->
    <component
      :class="{'-disabled': !option.hasStock}"
      @input="onChange"
      :is="type"
      v-bind="attrs"
      v-model="item"
    />
  </div>
</template>

<script>
import MixinCurrency from '@/helpers/currency'

import MultipleChoice from './option-multiple-choice'
import MultipleChoiceMultiple from './option-multiple-choice-multiple'
import MultipleChoiceMultipleSame from './option-multiple-choice-multiple-same'
import SingleChoice from './option-single-choice'

export default {
  name: 'CatalogProductOption',

  mixins: [
    MixinCurrency
  ],

  props: {
    option: {
      type: Object,
      required: true
    },
    item: {
      type: Object | String
    }
  },

  data() {
    return {
      selection: null
    }
  },

  computed: {
    attrs() {
      let ret = {}

      if (this.option){
        ret = this.option.toObject()
      }

      return ret
    },

    basePriceLabel() {
      return this.toCurrency(this.option.price.value, true)
    },

    classes() {
      return {
        'view-catalog-product__option': true,
        'option': true,

        '-is-required': this.option.required,
        '-disabled': !this.option.hasStock || this.option.isHidden,
      }
    },

    hasMax() {
      return !this.$basil.isNil(this.option.max) && this.option.max > 0 && this.option.max != 99
    },

    hasMin() {
      return !this.$basil.isNil(this.option.min) && this.option.min > 0 && this.option.min != 99
    },

    isRequired() {
      return this.option.required;
    },

    type() {
      let ret

      if (!this.option.hasValues){
        ret = SingleChoice
      }
      else if (this.option.hasValues && !this.option.isMultiple){
        ret = MultipleChoice
      }
      else if (this.option.hasValues && this.option.isMultiple && !this.option.isMultipleSame){
        ret = MultipleChoiceMultiple
      }
      else if (this.option.hasValues && this.option.isMultiple && this.option.isMultipleSame){
        ret = MultipleChoiceMultipleSame
      }

      return ret;
    }
  },

  methods: {
    onChange(selection) {
      this.selection = selection.selection
      this.$emit('selection-change', selection)
    }
  },

  mounted() {
    if(this.item != null) {
      this.selection = this.item
    }
  }
}
</script>
