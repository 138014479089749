<template>
  <div class="view-catalog-product-default">
    <!-- Hero -->
    <div class="view-catalog-product__illustration">
      <ui-hero
        v-if="hasFigure"
        :figures="figures"
        fit="cover"
        />
    </div>

    <!-- Header -->
    <header
      class="view-catalog-product__header">
      <div class="view-catalog-product__headings">
        <h2 class="view-catalog-product__title">{{ product.name }}</h2>
        <p
          v-if="product.description"
          class="view-catalog-product__description" v-html="description"
        ></p>

        <p 
          class="view-catalog-product__description"
          v-if="validityPeriod">
          {{ $t('resto.product_validity_period', { date: validityPeriodDate, days: validityPeriod }) }}
        </p>
      </div>

      <product-price
        :product="product"
        size="l"
        v-if="!allowUserPrice"
      />
    </header>

    <!-- User price -->
    <div
      class="view-catalog-product__options"
      v-if="allowUserPrice">
      <div class="view-catalog-product__option option">
        <!-- Header -->
        <h2 class="option__title group">
          <span>{{ $t('resto.product_gift_voucher_has_user_price') }}</span>
        </h2>

        <div class="option__fields">
          <div class="option__field">
            <forms-input
              :errors="errors['user_price']"
              :min="minUserPrice"
              :placeholder="$t('resto.placeholder')"
              :step="1"
              type="number"
              @input="onPriceInput"
              v-model="value.product.userPrice"
            ><template v-slot:suffix>{{ currencyCode }}</template></forms-input>
          </div>
        </div>
      </div>
    </div>

    <!-- User informations -->
    <div class="view-catalog-product__options">
      <div class="view-catalog-product__option option">
        <!-- Header -->
        <h2 class="option__title">
          <div class="option__field">
            <forms-checkbox
              v-model="value.product.isGift"
            >{{ $t('resto.product_gift_voucher_is_gift') }}</forms-checkbox>
          </div>
        </h2>

        <div
          class="option__fields"
          v-if="value.product.isGift">
          <div class="option__field">
            <forms-input
              :errors="errors['name']"
              :placeholder="$t('resto.placeholder')"
              required
              @input="onErrorRemove('name')"
              v-model="value.name"
            >{{ $t('resto.product_gift_voucher_receiver_name') }}</forms-input>
          </div>

          <div class="option__field">
            <forms-input
              :errors="errors['email']"
              :placeholder="$t('resto.placeholder')"
              required
              type="email"
              @input="onErrorRemove('email')"
              v-model="value.email"
            >{{ $t('resto.product_gift_voucher_receiver_email') }}</forms-input>
          </div>

          <div class="option__field">
            <forms-textarea
              :errors="errors['message']"
              :placeholder="$t('resto.placeholder')"
              required
              @input="onErrorRemove('message')"
              v-model="value.message"
            >{{ $t('resto.product_gift_voucher_receiver_message') }}</forms-textarea>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <footer class="view-catalog-product-default__footer">
      <div class="view-catalog-product-default__qty">
        <forms-qty
          v-if="value"
          :min="1"
          :noIncrease="!canIncrease"
          :noDecrease="!canDecrease"
          v-model="value.quantity"
        />
      </div>

      <div class="view-catalog-product-default__add">
        <actions-button
          :key="key"
          :disabled="!valid || isAdding"
          icon-pre="bag"
          :loading="isAdding"
          @click="onAddToCart"
          :data-price="price"
        ><span v-html="$t('resto.add_to_cart', { price: `<span id='view-catalog-product-price'>${price}</span>` })"></span></actions-button>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import FormsQty from '@/components/forms/qty'
import ProductPrice from './price'
import UiHero from '../ui/hero'

import MixinCatalogProduct from '../../mixins/catalog-product'
import MixinCurrency from '@/helpers/currency'
import MixinError from '../../mixins/error'
import MixinMultishop from '@/helpers/multishop'
import MixinText from '@/helpers/text'

import moment from 'dayjs'

export default {
  name: 'CatalogProductGiftVoucherBodyView',

  model: {
    prop: 'value',
    event: 'change',
  },

  inject: [ '$cart' ],

  mixins: [
    MixinCatalogProduct,
    MixinError,
    MixinCurrency,
    MixinMultishop,
    MixinText,
  ],

  props: {
    value: {}
  },

  components: {
    FormsQty,
    ProductPrice,
    UiHero,
  },


  data() {
    return {
      errors: {},
      isAdding: false,
      key: 1,
    }
  },

  computed: {
    ...mapState({
      product: state => state['sayl-front.catalog-product'].product,
    }),

    allowUserPrice() {
      return this.$basil.get(this.product, 'allowUserPrice', false)
    },

    canDecrease() {
      return this.value.quantity > 1
    },

    canIncrease() {
      return true
    },

    description() {
      let ret = this.$basil.get(this.product, 'description')
      return !this.$basil.isNil(ret) ? this.nl2br(ret) : null
    },

    hasPromoPrice() {
      return this.value && !this.$basil.isNil(this.value.total.promo)
    },

    minUserPrice() {
      return this.$basil.get(this.product, 'minUserPrice', 1)
    },

    price() {
      let v = this.value && !Number.isNaN(this.value.total.price.value) ? this.value.total.price : { value: 0 }
      return this.toCurrency(v.value);
    },

    promoPrice() {
      let v = this.value ? this.value.total.promo : 0
      return this.toCurrency(v * this.value.quantity)
    },

    valid() {
      let ret = !this.errors.hasOwnProperty('user_price')

      if(this.allowUserPrice) {
        ret = ret && this.$basil.get(this.value, 'product.userPrice', 0) >= this.$basil.get(this.value, 'product.minUserPrice', 0)
      }

      return this.key && ret
    },

    validityPeriod() {
      return this.$basil.get(this.value, 'product.validityPeriod', null) ? 
        this.$basil.get(this.value, 'product.validityPeriod', null) : null
    },

    validityPeriodDate() {
      let ret = this.validityPeriod

      if(ret) {
        ret = moment().add(ret, 'days')
      }

      return ret ? this.$basil.i18n.date(ret.toDate()) : null
    }
  },

  methods: {
    onErrorRemove(name) {
      delete this.errors[name]
    },

    onPriceInput() {
      delete this.errors.user_price
      if(this.allowUserPrice) {
        let valid = this.$basil.get(this.value, 'product.userPrice', 0) >= this.$basil.get(this.value, 'product.minUserPrice', 0)
        if(!valid) {
          this.errors['user_price'] = [this.$t('validation.price_not_valid')]
        }
        this.key++
      }
    },

    onAddToCart() {
      this.errors = {}
      this.isAdding = true
      this.$cart.add({ item: this.value, refresh: this.isMultiShop === true })
        .then(() => {
          this.$emit('close')
        })
        .catch((e) => {
          let status = this.$basil.get(e, 'response.status');
          if(status === 422) {
            this.errors = this.$basil.get(e, 'response.data.errors')
          }
          this.handleError(e)
        })
        .finally(() => this.isAdding = false)
    },
  },

  mounted() {
    if(this.allowUserPrice) {
      this.value.product.hasUserPrice = true
      this.value.product.userPrice = this.product.minUserPrice < this.product.unitPrice ?
        this.product.unitPrice : this.product.minUserPrice
    }
  }
}
</script>
