<!-- Pas cocher sur false/ cocher sur true -->
<template>
  <div class="option__fields">
    <div class="option__field">
      <forms-checkbox
        :name="id"
        :modelValue="true"
        v-model="choice"
        @change="propagate"
      >{{ $t('resto.single_choice_yes') }}</forms-checkbox>

      <span
        class="option__price"
        v-if="hasPriceVariation(variationPrice)"
      >{{ priceVariationLabel(variationPrice) }}</span>
    </div>
  </div>
</template>

<script>
import MixinOption from '@/modules/catalog/mixins/option'
import MixinCurrency from '@/helpers/currency'

export default {
  name: 'ProductOptionSingleChoice',

  mixins: [
    MixinCurrency,
    MixinOption
  ],

  computed: {
    price() {
      let ret = 0;

      if (this.choice) {
        if (!this.$basil.isNil(this.basePrice)) {
          ret = this.basePrice;
        }

        if (!this.$basil.isNil(this.variationPrice)) {
          price += this.variationPrice;
        }
      }

      return ret;
    },

    valid() {
      if (this.required) {
        return this.choice !== null ? this.choice : false;
      }
      return true;
    },
  },

  methods: {
    priceVariationLabel(variationPrice) {
      const sign = variationPrice > 0 ? '+' : '-';
      return `(${sign}${this.toCurrency(variationPrice)})`;
    },
  },

  created() {
    this.propagate();
  }
};
</script>
