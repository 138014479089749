<template>
  <div class="option__fields">
    <div
      :class="fieldClasses(value)"
      v-for="(value, i) in values"
      :key="i">
      <div class="b-flex b-flex-middle">
        <forms-qty
          v-model="choice[value.code]"
          size="s"
          :min="0"
          @increase="add()"
          @decrease="sub()"
          :noIncrease="!canAdd"
        />

        <forms-label class="option__label">{{ value.name }}</forms-label>
      </div>

      <span
        class="option__price"
        v-if="!value.isHidden && hasPriceVariation(value)"
      >{{ priceVariationLabel(value.variationPrice) }}</span>

      <data-tag
        v-if="!isHidden && value.isHidden"
        size="s"
      >{{$t('resto.option_unavailable')}}</data-tag>
    </div>
  </div>
</template>

<script>
import FormsQty from '@/components/forms/qty'

import MixinOption from '@/modules/catalog/mixins/option'
import MixinCurrency from '@/helpers/currency'

export default {
  name: 'ProductOptionMultipleSameMultiple',

  components: {
    FormsQty
  },

  mixins: [
    MixinCurrency,
    MixinOption
  ],

  data(){
    return {
      quantity: 0
    }
  },

  computed: {
    price() {
      let price = 0;

      if (this.quantity >= 1) {
        if (!this.$basil.isNil(this.basePrice)) {
          price += this.basePrice;
        }
      }

      if (this.choice && this.availableValues && this.availableValues.length){

        for (const o in this.choice) {
          for (let i = 0; i < this.availableValues.length; i++) {
            if (this.availableValues[i].code === o) {
              if (!this.$basil.isNil(this.availableValues[i].price_variation)) {
                price += this.choice[o] * this.availableValues[i].price_variation;
              }
            }
          }
        }
      }

      return price;
    },

    valid() {
      if (!this.$basil.isNil(this.minSelectedValues)) {
        return this.quantity >= this.minSelectedValues;
      }
      if (this.required) {
        return this.quantity >= 1;
      }
      return true;
    },

    canAdd() {
      return this.$basil.isNil(this.max) ? true : this.quantity < this.max;
    },
  },

  methods: {
    add() {
      this.updateQuantity();
      this.propagate();
    },

    priceVariationLabel(priceVariation) {
      const sign = priceVariation > 0 ? '+' : '-';
      return `(${sign}${this.toCurrency(priceVariation)})`;
    },

    sub() {
      this.updateQuantity();
      this.propagate();
    },

    updateQuantity(){
      this.quantity = 0;

      Object.keys(this.choice).forEach(k => {
        this.quantity += this.choice[k];
      });
    }
  },

  created() {
    this.choice = {};
    if (this.values && this.values.length > 0){
      this.values.forEach( e => {
        this.choice[e.code] = !e.isDefault || e.isHidden ? 0 : 1;
      });

    }

    this.propagate();
  },
};
</script>
