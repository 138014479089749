export default {
  model: {prop: 'selection', event: 'change'},

  props: {
    basePrice: {
      type: Number
    },

    hasStock: {
      type: Boolean,
      default: true,
    },

    id: {
      type: String
    },

    isHidden: {
      type: Boolean
    },

    min: {
      type: Number
    },

    max: {
      type: Number
    },

    required: {
      type: Boolean,
      default: false
    },

    selection: {
      type: Object | String,
    },

    values: {
      type: Array
    },

    variationPrice: {
      type: Number
    }
  },

  data() {
    return {
      choice: null,
    };
  },

  watch: {
    selection(){
      this.choice = this.selection;
    }
  },

  methods: {
    fieldClasses(value) {
      return {
        'option__field': true,
        '-disabled': value.isHidden,
      }
    },

    hasPriceVariation(value) {
      return !this.$basil.isNil(value.variationPrice) && value.variationPrice !== 0
    },

    priceVariationLabel(price) {
      return this.toCurrency(price, true);
    },
    
    propagate() {
      this.$emit('input', {
        selection: this.choice,
        valid: this.valid,
      });
    },
  },

  mounted() {
    if(this.selection) {
      this.choice = this.selection
    }
  }
}
