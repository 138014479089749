<template>
  <div class="view-catalog-product-default">
    <!-- Hero -->
    <div class="view-catalog-product__illustration">
      <ui-hero
        :figures="figures"
        fit="cover"
        v-if="hasFigure"
      />
    </div>

    <!-- Header -->
    <header
      class="view-catalog-product__header">
      <div class="view-catalog-product__headings">
        <h2 class="view-catalog-product__title">
          <span class="title">{{ product.name }}</span>

          <div class="tag">
            <data-tag
              :appearance="$pepper.Appearance.PRIMARY"
              v-if="$basil.get(product, 'adultOnly', false)"
            >{{ $t('resto.product_is_age_restricted') }}</data-tag>
          </div>
        </h2>

        <p
          class="view-catalog-product__description"
          v-html="description"
          v-if="product.description"
        ></p>
      </div>

      <product-price
        :product="product"
        size="l"
      />
    </header>

    <div class="view-catalog-product__options-error">
      <notice-banner
        :description="$t('resto.required_option_hidden_description')"
        :intent="$pepper.Intent.DANGER"
        :title="$t('resto.required_option_hidden_title')"
        v-if="hasHiddenRequiredOptions"
      />
    </div>

    <div
      class="view-catalog-product__options-error"
      v-if="hasPreparationTime">
      <notice-banner
        :intent="$pepper.Intent.WARNING"
        :title="$t('resto.product_modal_has_preparation_time_title', { value: preparationTime })"
      />
    </div>

    <!-- Variants -->
    <product-variant
      :item="value"
      :product="product"
      @change="onChangeVariant"
      v-if="hasMultipleVariants"
    />

    <!-- Options -->
    <div
      class="view-catalog-product__options"
      v-if="hasOptions">
      <product-option
        :key="option.id"
        :option="option"
        @selection-change="(values) => { onChangeSelection(values, option) }"
        v-for="option in options"
      />
    </div>

    <!-- Allergens -->
    <product-allergens :product="product" />

    <related-products
      :errors="relatedsErrors"
      @selection="onSelectRelated"
    />

    <!-- Footer -->
    <footer
      class="view-catalog-product-default__footer"
      v-if="!isCatalogReadonly">
      <div class="view-catalog-product-default__qty">
        <forms-qty
          :min="1"
          :noDecrease="!canDecrease"
          :noIncrease="!canIncrease"
          v-model="value.quantity"
          v-if="value"
        />
      </div>

      <div class="view-catalog-product-default__add">
        <actions-button
          :data-price="price"
          :disabled="!valid || !inStock || hasHiddenRequiredOptions"
          icon-pre="bag"
          @click="onAddToCart"
        ><span v-html="$t('resto.add_to_cart', { price: `<span id='view-catalog-product-price'>${price}</span>` })"></span></actions-button>
      </div>
    </footer>

    <!-- Footer -->
    <footer
      class="view-catalog-product-default__footer"
      v-if="isCatalogReadonly">
      <div class="view-catalog-product-default__footer-price">{{ price }}</div>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import FormsQty from '@/components/forms/qty'
import UiHero from '../ui/hero'

import ProductAllergens from './allergens'
import ProductOption from './option'
import ProductPrice from './price'
import ProductVariant from './variant'
import RelatedProducts from './related'

import MixinCatalogProduct from '../../mixins/catalog-product'
import MixinCurrency from '@/helpers/currency'
import MixinError from '../../mixins/error'
import MixinMultishop from '@/helpers/multishop'
import MixinText from '@/helpers/text'

export default {
  name: 'CatalogProductDefaultBodyView',

  model: {
    prop: 'value',
    event: 'change',
  },

  inject: ['$cart'],

  mixins: [
    MixinCatalogProduct,
    MixinError,
    MixinCurrency,
    MixinMultishop,
    MixinText
  ],

  props: {
    value: {}
  },

  components: {
    FormsQty,
    ProductAllergens,
    ProductOption,
    ProductPrice,
    ProductVariant,
    RelatedProducts,
    UiHero,
  },

  data() {
    return {
      relateds: [],
      relatedsErrors: []
    }
  },

  computed: {
    ...mapState({
      product: state => state['sayl-front.catalog-product'].product,
      allergens: state => state['sayl-front.catalog-product'].allergens
    }),

    canDecrease() {
      return this.value.quantity > 1
    },

    canIncrease() {
      return this.$basil.get(this.value, 'product.variant.stock.value', 0) == -1 ||
              this.$basil.get(this.value, 'quantity', 0) < this.$basil.get(this.value, 'product.variant.stock.value', 0)
    },

    description() {
      let ret = this.$basil.get(this.product, 'description')
      return !this.$basil.isNil(ret) ? this.nl2br(ret) : null
    },

    hasHiddenRequiredOptions() {
      let ret = false

      this.options && this.options.forEach((opt) => {
        if(!ret) {
          if(opt.required && opt.isHidden) {
            ret = true
          }
        }
      })

      return ret
    },

    hasMultipleVariants() {
      return this.product.variants.length > 1
    },

    hasOptions() {
      return this.options && this.options.length
    },

    hasPreparationTime() {
      return this.preparationTime > 0
    },

    hasPromoPrice() {
      return this.value && !this.$basil.isNil(this.value.total.promo);
    },

    isCatalogReadonly() {
      return this.$basil.get(this.oat, 'is_catalog_readonly', false)
    },

    inStock() {
      return this.$basil.get(this.value, 'product.variant.inStock')
    },

    options() {
      return this.product.options
    },

    preparationTime() {
      return this.product && this.$basil.get(this.product, 'preparationTime', 0)
    },

    price() {
      let v = this.$basil.get(this.value, 'total.price', 0)

      if(this.relateds.length > 0) {
        this.relateds.forEach(related => v.value += this.$basil.get(related, 'total.price.value'))
      }

      return this.toCurrency(v.value)
    },

    promoPrice() {
      let v = this.value ? this.value.total.promo : 0
      return this.toCurrency(v * this.value.quantity)
    },

    valid() {
      let ret = this.value && this.value.validate();
      this.relateds.length > 0 && this.relateds.forEach(related => {
        if(ret != false) {
          ret = related.validate()
        }
      })

      return ret
    },
  },

  methods: {
    onChangeSelection(value, option) {
      if (!option) {
        return
      }

      let opt = this.value.options.find(o => o.id === option.id)

      if (!opt) {
        return
      }

      opt.selection = value.selection instanceof Object ?
                      Object.assign({}, value.selection):
                      value.selection
    },

    onSelectRelated(selection) {
      this.relateds = Object.keys(selection).map(k => selection[k])
    },

    onChangeVariant(value) {
      this.value.product.variant = value
      this.key++
    },

    onAddToCart() {
      let promises = []
      this.relatedErrors = []

      if(!this.isCatalogReadonly) {

        if(this.relateds.length > 0) {
          this.relateds.forEach(related => {
            if(!related.validate()) {
              this.relatedsErrors.push(related.product.id)
            } else {
              promises.push(this.$cart.add.bind(this.$cart, { item: related, refresh: this.isMultiShop === true }))
            }
          })
        }

        if(Object.keys(this.relatedsErrors).length === 0){
          this.$basil.sequence(promises)
            .then(() => {
              if(!this.$basil.isNil(this.value)) {
                return this.$cart.add({ item: this.value, refresh: this.isMultiShop === true  })
              }
            })
            .catch((error) => {
              let status = this.$basil.get(error, 'response.status')
              let message = this.$basil.get(error, 'response.data.message')

              if(status === 422 && message.includes('stock')) {
                this.$notification({
                  title: this.$t('resto.no_stock_variant'),
                  message: this.$t('resto.error_adding_product_message_stock'),
                  type: 'error'
                })
              }
              $console.error(error)
            })
            .finally(() => this.$emit('close'))
        }
      }
    },
  },

  mounted() {
    this.relatedsErrors = []
  }
}
</script>
