<template>
  <div class="view-catalog-product-bundle">
    <!-- Header -->
    <div class="view-catalog-product__header">
      <div class="first">
        <div class="view-catalog-product__headings">
          <h2 class="view-catalog-product__title">{{ product.name }}</h2>
          <p
            class="view-catalog-product__description"
            v-html="description"
            v-if="product.description"
          ></p>
        </div>

        <product-price
          :product="product"
          size="l"
        />
      </div>

      <div
        class="second view-catalog-product__preparation-time"
        v-if="hasPreparationTime">
        <notice-banner
          :intent="$pepper.Intent.WARNING"
          :title="$t('resto.product_modal_has_preparation_time_title', { value: preparationTime })"
        />
      </div>
    </div>

    <!-- Navigation -->
    <ul
      id="view-catalog-product-bundle__steps"
      class="view-catalog-product-bundle__steps"
      v-if="needsConfiguration">
      <li
        :class="listClass(i)"
        :id="'group_' + i"
        :key="'group_' + i"
        @click="goToStep(i)"
        v-for="(group, i) in groups"
      >{{ group.name }}</li>
    </ul>

    <!-- loading -->
    <template v-if="(loading || reload)">
      <div class="layout-modal__loader">
        <ui-loader />
      </div>
    </template>

    <!-- Group selection -->
    <section
      class="view-catalog-product-bundle__group"
      v-if="(!loading && !reload) && needsConfiguration">

      <!-- Intro -->
      <div class="view-catalog-product-bundle__intro">
        <div
          class="view-catalog-product-bundle__group-description"
          v-if="group.description"
        >{{ group.description }}</div>
      </div>

      <!-- Selection -->
      <div class="view-catalog-product-bundle__selection">
        <!-- Item -->
        <div
          :class="itemClass(p.product)"
          :key="p.productId"
          v-for="p in group.items.filter(i => !$basil.get(i, 'product.hidden', false))">

          <!-- Product -->
          <label class="view-catalog-product-bundle__product">
            <forms-radio
              :key="key + '_t'"
              name="bundle"
              :value="p"
              @change="onChangeItem"
              v-model="currentStep.item"
            />

            <catalog-product-bundle-card
              appearance="subtle"
              class="-tablet-row -no-add"
              disabled
              hide-price
              :product="p.product"
            />
          </label>

          <!-- Variants -->
          <catalog-product-variants
            class="view-catalog-product-bundle__variants"
            :item="currentStep"
            :product="p.product"
            @change="onChangeVariant"
            v-if="isProductSelected(p) && productHasVariant(p)"
          />

          <!-- Options -->
          <div
            class="view-catalog-product__options"
            v-if="isProductSelected(p) && productHasOptions(p)">
            <catalog-product-option
              :item="currentStep.options[i].selection"
              :key="o.id"
              :option="o"
              @selection-change="(values) => { onChangeSelection(values, o) }"
              v-for="(o, i) in p.product.options"
            />
          </div>
        </div>
      </div>
    </section>

    <!-- Footer -->
    <footer
      class="view-catalog-product-bundle__footer"
      :key="key"
      v-if="!loading && needsConfiguration && !this.isCatalogReadonly">
      <!-- Back -->
      <actions-button
        :appearance="$pepper.Appearance.SUBTLE"
        class="view-catalog-product-bundle__previous"
        :disabled="!hasPreviousStep"
        icon-pre="arrow-left"
        @click="previous"
      >{{ $t('resto.back') }}</actions-button>

      <!-- Price -->
      <div
        :key="key + '_price'"
        class="view-catalog-product-bundle__price"
      >{{ price }}</div>

      <!-- Next -->
      <actions-button
        :appearance="$pepper.Appearance.SUBTLE"
        class="view-catalog-product-bundle__next"
        :disabled="!canContinue"
        :icon-post="nextIcon"
        @click="() => next()"
      >{{ nextLabel }}</actions-button>
    </footer>

    <!-- ReadOnly Footer -->
    <footer
      class="view-catalog-product-bundle__footer"
      v-if="isCatalogReadonly">
      <!-- Back -->
      <actions-button
        :appearance="$pepper.Appearance.SUBTLE"
        class="view-catalog-product-bundle__previous"
        :disabled="!hasPreviousStep"
        icon-pre="arrow-left"
        @click="previous"
      >{{ $t('resto.back') }}</actions-button>

      <div class="view-catalog-product-bundle__footer-price">{{ price }}</div>

      <!-- Next -->
      <actions-button
        :appearance="$pepper.Appearance.SUBTLE"
        class="view-catalog-product-bundle__next"
        :disabled="!hasNextStep"
        :icon-post="nextIcon"
        @click="() => next()"
      >{{ nextLabel }}</actions-button>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import CatalogProductBundleCard from './card-default'
import CatalogProductOption from './option'
import CatalogProductVariants from './variant'
import ProductAllergens from './allergens'
import ProductPrice from './price'

import MixinCurrency from '@/helpers/currency'
import MixinError from '../../mixins/error'
import MixinMultishop from '@/helpers/multishop'
import MixinText from '@/helpers/text'

export default {
  name: 'CatalogProductBundleBodyView',

  inject: [
    '$cart',
    '$catalog',
  ],

  components: {
    CatalogProductBundleCard,
    CatalogProductOption,
    CatalogProductVariants,
    ProductAllergens,
    ProductPrice,
  },

  mixins: [
    MixinCurrency,
    MixinError,
    MixinMultishop,
    MixinText,
  ],

  data() {
    return {
      loading: true,
      step: 0,
      steps: [],
      key: 1,
      total: 0,
      reload: false,
      needsConfiguration: false,
    }
  },

  computed: {
    ...mapState({
      product: state => state['sayl-front.catalog-product'].product,
      value: state => state['sayl-front.cart'].cartItem,
      statuses: state => state['sayl-front.cart'].statuses
    }),

    canContinue() {
      return this.key >= 0 && this.currentStep.isValid
    },

    currentStep() {
      return this.value.product.bundle[this.step];
    },

    description() {
      let ret = this.$basil.get(this.product, 'description')
      return !this.$basil.isNil(ret) ? this.nl2br(ret) : null
    },

    group() {
      return this.$basil.get(this.product, `bundle.${this.step}`, {})
    },

    groups() {
      return this.product.bundle
    },

    hasPreparationTime() {
      return this.preparationTime > 0
    },

    hasPreviousStep() {
      return this.step > 0
    },

    hasNextStep() {
      return this.step < this.groups.length - 1
    },

    isCatalogReadonly() {
      return this.$basil.get(this.oat, 'is_catalog_readonly', false)
    },

    isLastStep() {
      return (!this.loading && this.statuses) &&
        this.statuses.filter(s => s === 'done').length >= this.value.product.bundle.length
    },

    onLastStep() {
      let index = (!this.loading && this.statuses) ? this.statuses.indexOf(this.statuses.find(s => s !== 'done')) : -1
      return this.key && this.step === index
    },

    isValid() {
      return this.key && this.value.product.isValid && !this.isCatalogReadonly
    },

    nextIcon() {
      return  (this.isLastStep && this.onLastStep && this.isValid) || this.isValid ? 'bag' : 'arrow-right'
    },

    nextLabel() {
      return !this.isCatalogReadonly && (this.isLastStep && this.onLastStep && this.isValid) || this.isValid ?
              this.$t('resto.add-to-cart', { price: this.price }) :
              this.$t('resto.continue')
    },

    preparationTime() {
      return this.product && this.$basil.get(this.product, 'preparationTime', 0)
    },

    price(){
      return this.key && this.toCurrency(this.value.total.price.value)
    },
  },

  watch: {
    statuses: {
      deep: true,
      handler(val) {
        this.key++
      }
    },
  },

  methods: {
    goToStep(i, init = false){
      this.step = i
      this.reset(init)
    },

    isProductSelected(p) {
      return this.currentStep.item &&
             this.currentStep.item.productId === p.productId
    },

    isStepActive(i) {
      return this.step === i
    },

    isStepDone(i) {
      return this.statuses &&
              this.statuses[i] === 'done'
    },

    isStepVisited(i) {
      return this.statuses &&
             this.statuses[i] === 'visited'
    },

    itemClass(value) {
      return {
        'view-catalog-product-bundle__item': true,
        '-is-active': this.group.item && this.group.item.productId === value.id,
        '-is-disabled': !this.$basil.get(value, 'available', true)
      }
    },

    listClass(step) {
      return {
        'view-catalog-product-bundle__step': true,
        '-is-active': this.isStepActive(step),
        '-is-done': this.isStepDone(step),
        '-is-visited': this.isStepVisited(step) || this.isStepDone(step)
      }
    },

    next(init = false) {
      if(this.isCatalogReadonly && !(this.onLastStep)) {
        this.goToStep(this.step+1)
      } else {
        if((!this.isValid && !(this.onLastStep)) || init) {
          let next = null
          this.value.product.bundle.forEach((b, i) => next = this.$basil.isNil(next) && !b.isValid ? i : next)
          next = !this.$basil.isNil(next) ? next : this.value.product.bundle.length - 1
          this.goToStep(next, init)
        } else if(this.isValid && !this.$basil.isNil(this.value)) {
          this.$cart.add({ item: this.value, refresh: this.isMultiShop === true })
            .then(() => this.$emit('close'))
            .catch((error) => this.handleError(error))
        }
      }
    },

    onChangeItem(value) {
      if(!this.$basil.isNil(value) && this.$basil.get(value, 'product.inStock', true)) {
        this.$cart.setCartItem({ item: value, groupId: this.step })
          .then(() => this.key++)
          .catch((error) => this.handleError(error))
      } else {
        if(!this.$basil.isNil(this.currentStep)){
          this.currentStep.item = null
        }
        this.key++
      }
    },

    onChangeSelection(values, option) {
      let o = this.currentStep.options.find(o => o.id === option.id)
      o.selection = values.selection

      this.key++;
    },

    onChangeVariant(variant) {
      this.currentStep.variant = variant
      this.key++
    },

    previous() {
      if(this.step > 0) {
        this.goToStep(this.step - 1)
      }
    },

    productHasVariant(p) {
      return p.product &&
             p.product.variants &&
             p.product.variants.length > 1
    },

    productHasOptions(p) {
      return p.product &&
             p.product.options &&
             p.product.options.length > 0
    },

    scrollTo() {
      let nav = document.getElementById('view-catalog-product-bundle__steps')
      let group = document.getElementById('group_'+(this.step))

      if(nav && group) {
        nav.scrollTo(group.offsetLeft - nav.clientWidth + group.clientWidth, 0)
      }
    },

    reset(init = false) {
      let productId = this.$route.params.product
      if(!productId) {
        return;
      }

      let status = this.statuses[this.step]

      if(!['done', 'visited'].includes(status)){
        this.loading = true
        this.$catalog.product.viewProductsByGroup({ groupId: this.step })
          .then(() => {
            if(!this.$basil.isNil(this.group.items) && this.group.items.length === 1 && !this.group.isValid) {
              this.onChangeItem(this.group.items[0])
              if(this.currentStep.isValid && !this.productHasOptions(this.group.items[0]) && !this.productHasVariant(this.group.items[0]) && !this.isLastStep) {
                this.reload = true
                this.next(init)
              } else {
                this.needsConfiguration = true
                this.reload = false
              }
            } else {
              this.needsConfiguration = true
              this.reload = false
            }

            this.statuses[this.step] = this.statuses[this.step] !== 'done' ? 'visited' : 'done'
          })
          .catch((error) => {
            $console.error(error)
            console.error(error)
          })
          .finally(() => {
            this.loading = false
            this.scrollTo()
          })
      } else {
        this.loading = false
        this.needsConfiguration = true
        this.reload = false
        this.key++
        this.scrollTo()
      }
    }
  },

  mounted() {
    this.reset(true)
  }
}
</script>
