<template>
  <details
    class="product-allergens"
    :key="key"
    :open="opened"
    v-if="hasAllergens">
    <summary
      class="product-allergens__summary"
      @click="() => { opened = !opened; key++ }"
    >{{ !opened ? $t('resto.show_allergens', { amount }) : $t('resto.allergens') }}</summary>

    <div class="product-allergens__items">
      <div
        class="product-allergens__item"
        :class="hasAllergen(allergen) ? '-is-active' : '-is-disabled'"
        v-for="(allergen) in actives"
        :key="allergen.code">

        <ui-icon
          class="product-allergens__icon"
          :glyph="icon(allergen)"
        />

        <span class="product-allergens__label">{{ getAllergenName(allergen) }}</span>
      </div>
    </div>
  </details>
</template>

<script>
import MixinAllergens from '../../mixins/allergens'

export default {
  name: 'ProductAllergens',

  inject: [ '$user' ],

  mixins: [
    MixinAllergens,
  ],

  props: {
    product: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      opened: false,
      key: 1
    }
  },

  computed: {
    actives() {
      let ret = this.allergens.filter(a => this.hasAllergen(a))
      return ret
    },

    amount() {
      return this.actives.length
    },

    hasAllergens() {
      return !this.$basil.isEmpty(this.actives)
    },

    inactives() {
      let ret = this.allergens.filter(a => !this.hasAllergen(a))
      return ret
    },

    lang() {
      let ret = this.$basil.get(this.user, 'lang', 'all')
      return ret
    },

    list() {
      return this.actives.concat(this.inactives)
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },
  },

  methods: {
    hasAllergen(allergen) {
      let allergens = this.$basil.get(this.product, 'allergens', [])
      let ret = allergens.find(a => a === allergen.id)

      return !this.$basil.isNil(ret)
    },

    icon(allergen) {
      return this.hasAllergen(allergen) ? 'check' : 'cross'
    },
  }
}
</script>
