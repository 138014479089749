import { mapGetters } from 'vuex'

export default {
  inject: ['$user'],

  computed: {
    ...mapGetters({
      oat: 'bootstrap/oat'
    }),
    
    allergens() {
      return this.$basil.get(this.oat, 'allergens', [])
    },

    lang(){
      let ret = this.$basil.get(this.user, 'lang', 'all');
      return ret
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },
  },

  methods: {
    getAllergenName(allergen) {
      let ret = this.$basil.get(allergen, `name.${this.lang}`, null)
      if(this.$basil.isNil(ret) || this.$basil.isEmpty(ret)) {
        ret = this.$basil.get(allergen, 'name.all', null)
      }
      return ret
    },

  }
}
