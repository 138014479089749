<template>
  <div class="qty" :class="classes">
    <button
      class="qty__less"
      :disabled="noDecrease"
      @click="decrease"></button>

    <div class="qty__body">
      <output class="qty__figure">{{ currentValue }}</output>
      <span class="qty__suffix">{{ suffix }}</span>
    </div>

    <button
      class="qty__more"
      :disabled="noIncrease"
      @click="increase">
    </button>
  </div>
</template>

<script>
export default {
  name: 'FormsQty',

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {

    size:{
      type: String,
      values: ['s', 'm', 'l']
    },

    suffix:{
      type: String,
      default: 'x'
    },

    value: {
      type: Number,
      default: 1
    },

    step: {
      type: Number,
      default: 1
    },

    min:{
      type: Number,
      default: -((2 ** 53) - 1)
    },

    max:{
      type: Number,
      default: (2 ** 53) - 1
    },

    noIncrease:{
      type: Boolean,
      default: false
    },

    noDecrease: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      currentValue: 0
    }
  },

  watch: {
    value(val) {
      this.currentValue = val;
    }
  },

  methods:{
    increase(){
      if (this.noIncrease){
        return
      }

      this.currentValue = this.currentValue + this.step <= this.max ? this.currentValue + this.step : this.max
      this.$emit('change', this.currentValue);
      this.$emit('increase', this.currentValue);
    },

    decrease(){
      if (this.noDecrease){
        return;
      }

      this.currentValue = this.currentValue - this.step >= this.min ? this.currentValue - this.step : this.min
      this.$emit('change', this.currentValue);
      this.$emit('decrease', this.currentValue);
    }
  },

  created(){
    this.currentValue = this.value;
  },

  computed:{
    classes(){
      return {
        '-s': this.size == 's',
        '-l': this.size == 'l',
        '-no-decrease': this.noDecrease,
        '-no-increase': this.noIncrease
      }
    }
  }
}
</script>
